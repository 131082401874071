import { z } from "zod";
export type ActionArgs = {
    request: Request
    userId: string
    formData: FormData
}

export const SecuritySchema = z.object({
    api: z.literal('security'),
    domain: z.string(),
})

export const EntityResolutionSchema = z.object({
    api: z.literal('entity_resolution'),
    name: z.string(),
    location: z.string().optional(),
})

export const EnrichmentSchema = z.object({
    api: z.literal('enrichment'),
    domain: z.string(),
})