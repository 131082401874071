interface AddressValue {
    street_number?: string | null
    street_name?: string | null
    sub_premise?: string | null
    city?: string | null
    postal_code?: string | null
    state?: string | null
    state_code?: string | null
    country?: string | null
    country_code?: string | null
    formatted_address?: string | null
}

interface AddressViewProps {
    address: AddressValue
    className?: string
}

export function AddressView({ address, className }: AddressViewProps) {
    // If we have a formatted address, use that
    if (address?.formatted_address) {
        return (
            <div className={className}>
                {address.formatted_address}
            </div>
        )
    }

    // Otherwise build the address from components
    const lines: string[] = []

    // Line 1: Street address
    const streetParts = [
        address?.street_number,
        address?.street_name,
        address?.sub_premise
    ].filter(Boolean)
    if (streetParts.length > 0) {
        lines.push(streetParts.join(' '))
    }

    // Line 2: City, State ZIP
    const cityParts = [
        address?.city,
        address?.state_code || address?.state,
        address?.postal_code
    ].filter(Boolean)
    if (cityParts.length > 0) {
        lines.push(cityParts.join(', '))
    }

    // Line 3: Country
    if (address?.country) {
        lines.push(address.country)
    }

    return (
        <div className={className}>
            {lines.map((line, i) => (
                <div key={i} className="text-muted-foreground">
                    {line}
                </div>
            ))}
        </div>
    )
} 