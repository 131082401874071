import { useState } from "react"
import { Button } from "./ui/button.tsx"
import { ThumbsDown, ThumbsUp } from "lucide-react"
import { cn } from "#app/utils/misc.tsx"

interface FeedbackOption {
    id: string
    label: string
}

const defaultOptions: FeedbackOption[] = [
    { id: "incorrect", label: "Not factually correct" },
    { id: "incomplete", label: "Missing important information" },
    { id: "outdated", label: "Information is outdated" },
    { id: "other", label: "Other issue" }
]

interface FeedbackProps {
    onSubmit: (feedback: { wasHelpful: boolean, comments?: string, reason?: string }) => void
    options?: FeedbackOption[]
}

export function Feedback({ onSubmit, options = defaultOptions }: FeedbackProps) {
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string>()
    const [comments, setComments] = useState("")

    const handleFeedback = (wasHelpful: boolean) => {
        if (wasHelpful) {
            onSubmit({ wasHelpful: true })
        } else {
            setShowOptions(true)
        }
    }

    const handleSubmitFeedback = () => {
        onSubmit({
            wasHelpful: false,
            reason: selectedOption,
            comments: comments.trim() || undefined
        })
        setShowOptions(false)
        setSelectedOption(undefined)
        setComments("")
    }

    return (
        <div className="mt-4 border-t pt-4">
            {!showOptions ? (
                <div className="flex items-center gap-4">
                    <span className="text-sm text-muted-foreground">Was this response helpful?</span>
                    <div className="flex gap-2">
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleFeedback(true)}
                        >
                            <ThumbsUp className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleFeedback(false)}
                        >
                            <ThumbsDown className="h-4 w-4" />
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="space-y-4">
                    <h4 className="text-sm font-medium">What was the issue with this response?</h4>
                    <div className="grid gap-2">
                        {options.map((option) => (
                            <Button
                                key={option.id}
                                variant="outline"
                                className={cn(
                                    "justify-start",
                                    selectedOption === option.id && "border-primary"
                                )}
                                onClick={() => setSelectedOption(option.id)}
                            >
                                {option.label}
                            </Button>
                        ))}
                    </div>
                    <textarea
                        className="w-full rounded-md border p-2 text-sm"
                        placeholder="Additional comments (optional)"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        rows={3}
                    />
                    <div className="flex justify-end gap-2">
                        <Button
                            variant="ghost"
                            onClick={() => setShowOptions(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmitFeedback}
                            disabled={!selectedOption}
                        >
                            Submit feedback
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
} 