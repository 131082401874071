import { Progress } from "./ui/progress.tsx"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./ui/card.tsx"
import { Building2 } from "lucide-react"
import { AddressView } from "./address-view.tsx"
import { EntityResolutionResponse } from "#app/types/api.ts"

interface EntityResolutionViewProps {
    data: EntityResolutionResponse
}

export function EntityResolutionView({ data }: EntityResolutionViewProps) {
    const { code, match, possible_matches } = data
    
    return (
        <div className="space-y-6">
            {/* Header with match status and score */}
            <div className="flex items-center justify-between">
                <div>
                    <h2 className="text-2xl font-bold">
                        {code === 'match' ? 'Match Found' : 'No Exact Match'}
                    </h2>
                    {/* <p className="text-muted-foreground">
                        {possible_matches?.count} possible matches found
                    </p> */}
                </div>
                {match && (
                    <div className="flex items-center gap-4">
                        <div className="text-right">
                            <div className="text-sm text-muted-foreground">Confidence Score</div>
                            <div className="text-2xl font-bold">{match.score}%</div>
                        </div>
                        <Progress value={match.score} className="w-24" />
                    </div>
                )}
            </div>

            {/* Primary match card */}
            {match && (
                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                            <Building2 className="h-5 w-5" />
                            {match.record.name.value}
                        </CardTitle>
                        {match.record.legal_name.value !== match.record.name.value && (
                            <CardDescription>
                                Legal Name: {match.record.legal_name.value}
                            </CardDescription>
                        )}
                    </CardHeader>
                    <CardContent className="grid gap-6">
                        {/* Company details in a more organized grid */}
                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <h3 className="font-medium mb-2">Primary Address</h3>
                                <AddressView address={match.record.primary_address.value} />
                            </div>
                            <div>
                                <h3 className="font-medium mb-2">Legal Address</h3>
                                <AddressView address={match.record.legal_address.value} />
                            </div>
                        </div>
                        
                        {/* Company identifiers */}
                        <div className="grid md:grid-cols-2 gap-4">
                            {match.record.website.value && (
                                <div>
                                    <h3 className="font-medium mb-2">Website</h3>
                                    <a 
                                        href={match.record.website.value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary hover:underline"
                                    >
                                        {match.record.website.value}
                                    </a>
                                </div>
                            )}
                            {match.record.identifiers.primary_regional.value?.id && (
                                <div>
                                    <h3 className="font-medium mb-2">Government ID</h3>
                                    <p className="text-muted-foreground">
                                        {match.record.identifiers.primary_regional.value.id}
                                        {' '}
                                        ({match.record.identifiers.primary_regional.value.region_code})
                                    </p>
                                </div>
                            )}
                        </div>
                        
                        {/* DBA Names section */}
                        {match.record.dba_names.value.length > 0 && (
                            <div>
                                <h3 className="font-medium mb-2">DBA Names</h3>
                                <ul className="list-disc list-inside text-muted-foreground">
                                    {match.record.dba_names.value.map((dba: string, index: number) => (
                                        <li key={index}>{dba}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        
                        {/* Description section */}
                        <div>
                            <h3 className="font-medium mb-2">Description</h3>
                            <p className="text-muted-foreground">
                                {match.record.description.value}
                            </p>
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Possible matches section */}
            {possible_matches?.results?.length > 0 && (
                <div>
                    <h3 className="text-lg font-medium mb-4">Other Possible Matches</h3>
                    <div className="grid gap-4">
                        {possible_matches.results.map((result: any, index: number) => (
                            <Card key={index}>
                                {/* Similar layout to primary match but more compact */}
                            </Card>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
} 