import { ActionFunctionArgs, DataFunctionArgs, redirect, type MetaFunction } from '@remix-run/node'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#app/components/ui/tooltip.tsx'

// import {
// 	Table,
// 	TableHead,
// 	TableHeaderCell,
// 	TableBody,
// 	TableRow,
// 	TableCell,
// 	Title
//   } from "@tremor/react";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "#app/components/ui/table.tsx"
import { getUserId, loadUser } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts';
import { Link, NavLink, Outlet, isRouteErrorResponse, json, useActionData, useLoaderData, useRouteError } from '@remix-run/react';
import { cn } from '#app/utils/misc.tsx';
import { Dashboard } from './_dashboard/_main.tsx';
import { Playground, action as PlaygrounAction } from './_dashboard/_playground.tsx';
import Marketing from './_marketing+/index.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
// import { logos, stars } from './logos/logos.ts'


export async function loader({ request }: DataFunctionArgs) {
	const userId = await getUserId(request);
	if (!userId) {
		return {publicUser: true}
	}

	const user = await loadUser(request);
	if (!user) {
		return redirect('/login')
	}

	if (!user?.account?.account?.accountOption?.activated) {
		return redirect('/activation')
	}

	return { user };
}

export async function action({ request, params, context }: ActionFunctionArgs) {
    return PlaygrounAction({request, params, context});
}

export const meta: MetaFunction = () => [{ title: 'SavvyIQ' }]

export function ErrorBoundary() {
	const error = useRouteError();

	return (
		<div className="flex flex-col gap-6 items-center">
			<div className="flex flex-col gap-3 items-center">
				<h1 className='text-2xl'>Oops! An error occurred while rendering this page.</h1>
				<p className='text-lg'>Please try again later</p>
			</div>
			<Link to="/" className="text-body-md underline">
				<Icon name="arrow-left">Back to home</Icon>
			</Link>
		</div>
	);
  
	if (isRouteErrorResponse(error)) {
	  return (
		<div>
		  <h1>
			{error.status} {error.statusText}
		  </h1>
		  <p>{error.data}</p>
		</div>
	  );
	} else if (error instanceof Error) {
	  return (
		<div>
		  <h1>Error</h1>
		  <p>{error.message}</p>
		  <p>The stack trace is:</p>
		  <pre>{error.stack}</pre>
		</div>
	  );
	} else {
	  return <h1>Unknown Error</h1>;
	}
  }

export default function Index() {
	const data = useLoaderData<typeof loader>();

	if (data.publicUser) {
		return <Marketing/>
	}

	return (
		<div>
			{/* {<Dashboard/>} */}
			<Playground/>
		</div>
	)
}
